
import React from "react";

import Globals from "Classes/Globals";
import { WriteNumber } from "Functions";

import Buttons from "Components/UI/Buttons";
import Center from "Components/Layout/Center";

class Start extends React.Component {

    constructor( props ) {

        super( props );

        this.items = [

            [ "Starta", true, "green", Globals.Settings.BindTrue ],
            [ "Tillbaka", false, "red", Globals.Settings.BindFalse ]

        ];

    }

    componentDidMount() {

        Globals.header( true );

    }

    componentWillUnmount() {

        Globals.header( false );

    }

    navigate = ( index, start ) => {

        if ( start ) {

            this.props.onStart();

        }

        else {

            Globals.var( "player", false );

        }

    }

    render() {

        return (

            <Center className="GameStart">

                <div className="GameStartRules">

                    <h2>Så här går det till</h2>

                    <hr />

                    <div className="GameStartRulesText">

                        <p>Om du tror att påståendet som visas på skärmen stämmer trycker du på den gröna <i>FAKTA</i>-knappen. Om du tror att påståendet är falskt trycker du på den röda <i>FIKTION</i>-knappen.</p>

                        <p>Tänk på att det är viktigare att trycka rätt än att trycka snabbt. Varje rätt svar ger en poäng och <i>{ WriteNumber( Globals.Rules.Reward ) }</i> extra spel-sekunder. Du får spela så länge du har tid kvar, men efter <i>{ WriteNumber( Globals.Rules.MaxErrors ) }</i> felaktiga svar tar spelet slut.</p>

                    </div>

                </div>

                <Buttons
                
                    arrows={ false }
                    items={ this.items }
                    onClick={ this.navigate }
                
                />

            </Center>

        );

    }

}

Start.defaultProps = {

    onStart: () => {}

};

export default Start;