
import React from "react";
import "./footer.scss";

import Sida from "Components/Layout/Sida";

class Footer extends React.Component {

    render() {

        const classNamesArray = [ "Footer" ];

        if ( this.props.show ) {

            classNamesArray.push( "Show" );

        }

        const classNamesString = classNamesArray.join( " " );

        return (

            <div className={ classNamesString }>

                <Sida />

            </div>

        );

    }

}

Footer.defaultProps = {

    show: false

};

export default Footer;