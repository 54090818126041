
export const CapFloat = ( number, min = 0, max = 1 ) => {

    if ( typeof number !== "number" || number < min ) {

        return min;

    }

    if ( number > max ) {

        return max;

    }

    return number;

}


export const NoOrphans = ( str, wrap = 15 ) => {

    if ( typeof str !== "string" ) {

        return str;

    }

    const Words = str.split( " " );
    let Word, Last = ""

    Words.forEach( word => {

        if ( Last.length > wrap ) {

            return;

        }

        Word = Words.pop();
        Last = Last ? Word + "\u00A0" + Last : Word;

    } );

    Words.push( Last );

    return Words.join( " " );

}

export const PadNumber = ( number, length = 2 ) => {

    let padded = number.toString();

    while ( padded.length < length ) {

        padded = "0" + padded;

    }

    return padded;

}

export const WriteNumber = ( number ) => {

    const strings = [ "noll", "ett", "två", "tre", "fyra", "fem", "sex", "sju", "åtta", "nio", "tio", "elva", "tolv", "tretton", "fjorton", "femton", "sexton", "sjutton", "arton", "nitton" ];

    if ( typeof number !== "number" || number < 0 || number >= strings.length ) {

        return number;

    }

    return strings[ number ];

}

export const RandomToken = ( length = 16 ) => {

    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvxyz012345678901234567890123456789";
    let token = "";

    for ( var i = 0; i < length; i++ ) {

        token += chars.charAt( Math.round( Math.random() * ( chars.length - 1 ) ) );

    }

    return token;

}

export const SetUri = ( uri ) => {

    if ( typeof window.history === "object" && typeof window.history.replaceState === "function" ) {

        window.history.replaceState( {}, "", uri );

    }

}

export const ShuffleArray = ( arr ) => {

    arr.forEach( ( value, key ) => {

        const move = Math.floor( Math.random() * ( key + 1 ) );

        [ arr[ key ], arr[ move ] ] = [ arr[ move ], arr[ key ] ];

    } );

    return arr;

}

export const SortArrayObjects = ( obj, key, order = 1 ) => {

    if ( typeof obj !== "object" ) {

        return;

    }

    const keys = typeof key === "object" ? key : [ key ];
    const orders = typeof order === "object" ? order : [ order ];

    obj.sort( ( a, b ) => {

        let ret = 0;

        keys.forEach( ( k, i ) => {

            const o = typeof orders[i] !== "undefined" ? orders[i] : orders[0];

            if ( !ret && a[ k ] < b[ k ] ) {

                ret = o;

            }

            else if ( !ret && a[ k ] > b[ k ] ) {

                ret = -o;

            }

        } );

        return ret;

    } );

}

export const UCFirst = ( str ) => {

    if ( typeof str !== "string" ) {

        return "";

    }

    return str[0].toUpperCase() + str.substr(1).toLocaleLowerCase();

}

export const Uri = ( segmentIndex ) => {

    const segments = window.location.pathname.replace( /^\/|\/$/, "" ).split( '/' );

    if ( typeof segmentIndex === "undefined" ) {

        return segments.join( "/" );

    }

    else if ( segmentIndex === "first" ) {

        segmentIndex = 0;

    }

    else if ( segmentIndex === "last" ) {

        segmentIndex = segments.length - 1;

    }

    if ( typeof segments[ segmentIndex ] === "undefined" ) {

        return "";

    }

    return segments[ segmentIndex ].replace( /[^a-z0-9\-_]/gi, "" );

}

export const Url = ( uri = "" ) => {

    uri = typeof uri === "object" ? uri.join( "/" ) : uri;

    return window.location.protocol + "//" + window.location.host + "/" + uri;

}

export const ValidateEmail = ( str ) => {

    var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test( String( str ).toLocaleLowerCase() ) ? str : false;

}
