
import React from "react";

class Intro extends React.Component {

    render() {

        return (

            <div className="Intro">

                { this.props.justText ? "" : (

                    <span>

                        <h2>UPPDATERA DIN VÄRLDSBILD</h2>

                        <hr />

                    </span>

                ) }

                <p>Mångas bild av tillståndet i världen är mörk, trots att mycket har blivit bättre. Genom biståndsmyndigheten Sidas quiz <i>FAKTA eller FIKTION</i> kan du testa hur bra koll du har på läget i världen och samtidigt uppdatera din världsbild. Hur snabbt kan du avslöja en lögn?</p>

            </div>

        );

    }

}

Intro.defaultProps = {

    justText: false

};

export default Intro;