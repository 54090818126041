
import React from "react";
import "./sida.scss";

class Sida extends React.Component {

    svg = () => {

        return (

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.286 40">
                <g transform="translate(-0.014 -0.014)">
                    <g transform="translate(0.014 0.014)">
                        <g transform="translate(19.026 19.047)">
                            <rect width="1.347" height="1.347" transform="translate(0 0.952) rotate(-45)"/>
                            <path d="M106.8,106.9" transform="translate(-105.851 -105.953)"/>
                        </g>
                        <path d="M57.3.572l10.4,10.4L65.64,13.03,55.221,2.63A1.566,1.566,0,0,1,55.053.4,1.588,1.588,0,0,1,57.3.572Zm6.135.112,7.277,7.277-2.058,2.058L61.375,2.742A1.566,1.566,0,0,1,61.207.516,1.566,1.566,0,0,1,63.433.685ZM52.6,1.9,64.667,13.984,62.61,16.042,50.526,3.977a1.566,1.566,0,0,1-.168-2.226A1.6,1.6,0,0,1,52.6,1.9ZM51.33,6.671,61.674,17.015l-2.058,2.058L49.272,8.728A1.566,1.566,0,0,1,49.1,6.5,1.566,1.566,0,0,1,51.33,6.671Z" transform="translate(-39.604 -0.014)"/>
                        <path d="M.572,60.849l10.4-10.4,2.058,2.058-10.4,10.4A1.566,1.566,0,0,1,.4,63.075,1.566,1.566,0,0,1,.572,60.849Zm.094-6.173L7.942,47.4,10,49.458,2.724,56.734A1.566,1.566,0,0,1,.5,56.9,1.6,1.6,0,0,1,.666,54.677ZM1.9,65.507,13.984,53.423l2.058,2.058L3.958,67.565a1.566,1.566,0,0,1-2.226.168A1.566,1.566,0,0,1,1.9,65.507Zm4.751,1.272L17,56.435l2.058,2.058L8.728,68.837a1.566,1.566,0,0,1-2.226.168A1.58,1.58,0,0,1,6.652,66.779Z" transform="translate(-0.014 -38.536)"/>
                        <path d="M61.012,130.5l-10.4-10.4,2.058-2.058,10.4,10.4a1.566,1.566,0,0,1,.168,2.226A1.583,1.583,0,0,1,61.012,130.5Zm-6.135-.112L47.6,123.111l2.058-2.058,7.276,7.277a1.566,1.566,0,0,1,.168,2.226A1.6,1.6,0,0,1,54.877,130.388Zm10.831-1.235L53.623,117.069l2.058-2.058L67.765,127.1a1.472,1.472,0,1,1-2.058,2.058Zm1.272-4.77L56.635,114.058,58.692,112l10.344,10.344a1.566,1.566,0,0,1,.168,2.226A1.586,1.586,0,0,1,66.979,124.383Z" transform="translate(-38.699 -91.052)"/>
                        <path d="M130.5,57.3,120.1,67.7l-2.058-2.058,10.4-10.4a1.566,1.566,0,0,1,2.226-.168A1.583,1.583,0,0,1,130.5,57.3Zm-.112,6.135-7.277,7.277-2.058-2.058,7.276-7.277a1.472,1.472,0,1,1,2.058,2.058ZM129.172,52.6,117.088,64.667,115.03,62.61l12.084-12.084a1.566,1.566,0,0,1,2.226-.168A1.623,1.623,0,0,1,129.172,52.6ZM124.4,51.33,114.058,61.674,112,59.617l10.344-10.344A1.472,1.472,0,1,1,124.4,51.33Z" transform="translate(-91.052 -39.604)"/>
                    </g>
                    <path d="M306.664,57.763c-.692.561-3.049,2.45-4.864,2.45-1.571,0-2.338-1.366-2.338-2.768,0-2.694,2.974-4.3,5.069-5.144l2.132-.879Zm5.724.524c-.281,1.646-1.16,1.889-1.646,1.889-1.253,0-1.253-1.216-1.253-3.535v-6.51c0-1.964,0-5.63-6.117-5.63-4.938,0-6.547,2.619-6.547,3.7a1.135,1.135,0,0,0,1.216,1.122c1.328,0,1.534-1.366,1.684-2.207.281-1.4,1.889-1.721,3.292-1.721,3.292,0,3.666,1.927,3.666,4.3v.8l-3.049,1.253c-5.556,2.245-7.407,4.19-7.407,6.472,0,2.338,2.17,3.423,3.984,3.423,2.207,0,4.508-1.4,6.472-2.825.206.973.524,2.825,3.143,2.825a3.154,3.154,0,0,0,3.292-3.348h-.73Zm-22.035-.393a6.389,6.389,0,0,1-5.144,2.825c-1.889,0-5.275-1.216-5.275-7.613,0-1.216.037-7.407,5.144-7.407a8.423,8.423,0,0,1,5.275,2.3Zm0-11.036a8.956,8.956,0,0,0-5.836-2.376c-4.19,0-7.838,3.423-7.838,8.773s3.947,8.6,7.651,8.6a8.9,8.9,0,0,0,6.192-2.9,11.915,11.915,0,0,0,.243,2.9,37.775,37.775,0,0,1,5.069-.524v-.879c-2.656,0-2.656-.243-2.656-4.62V34.194a6.375,6.375,0,0,0-1.5.281,21.211,21.211,0,0,1-4.134.561v.879c2.825,0,2.825.168,2.825,4.62v6.323Zm-16.742-2.376a12.555,12.555,0,0,0-2.207.486,34.971,34.971,0,0,1-3.666.561v.879c2.825,0,3.049,0,3.049,4.433v4.863c0,4.19,0,4.62-2.376,4.62h-.673V61.2h8.679v-.879c-2.825,0-2.825-.168-2.825-4.62V44.482ZM249.519,60.943h.973a6.311,6.311,0,0,1,.973-2.581,9.391,9.391,0,0,0,7.314,3.348c5.107,0,8.361-3.18,8.361-7.688,0-5.069-3.255-6.6-8.455-8.885-3.105-1.366-6.4-2.825-6.4-5.986,0-1.777,1.216-4.059,4.583-4.059,3.143,0,5.948,2.1,7.071,6.472h.842V34.586h-.842a4.283,4.283,0,0,1-1.048,2.338,7.706,7.706,0,0,0-6.154-2.825c-4.433,0-7.033,3.143-7.033,6.6,0,5.069,4.377,6.959,7.37,8.249.243.075,1.571.636,1.814.767,2.694,1.16,5.425,2.694,5.425,5.836,0,2.45-1.964,5.181-5.911,5.181-6.229,0-7.557-6.51-7.931-8.361H249.5v8.567ZM269.5,39.917a2.069,2.069,0,0,0,2.02,2.058,2.058,2.058,0,1,0-2.02-2.058Z" transform="translate(-202.818 -27.71)"/>
                </g>
            </svg>

        );

    }

    render() {

        const width = this.props.width;
        const height = width * ( 4 / 11 );

        const style = {

            width: width,
            height: height

        };

        return (

            <a className="Sida" style={ style } href="https://www.sida.se/" target="_blank" rel="noopener noreferrer">
            
                { this.svg() }
            
            </a>

        );

    }

}

Sida.defaultProps = {

    width: 110

};

export default Sida;