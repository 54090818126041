
import React from "react";
import "./register.scss";

import Globals from "Classes/Globals";
import { RandomToken } from "Functions";

import Buttons from "Components/UI/Buttons";
import Center from "Components/Layout/Center";
import Checkbox from "Components/UI/Fields/Checkbox";
import Intro from "Components/Layout/Intro";
import ScreenField from "Components/UI/ScreenField";
import ScreenTerms from "Components/UI/ScreenTerms";
import TextField from "Components/UI/Fields/TextField";

class Register extends React.Component {

    constructor( props ) {

        super( props );

        this.buttons = [

            [ "Nästa", true, "green", Globals.Settings.BindTrue ],
            [ "Tillbaka", false, "red", Globals.Settings.BindFalse ]

        ];

        this.fields = [

            [ "name", "Ditt namn", "text", true ],
            [ "email", "Din e-postadress (valfri)", "email" ],
            /*[ "gdpr", (

                <p>

                    Jag godkänner att mina uppgifter sparas enligt användarpolicyn.

                </p>

            ), "checkbox", true ]*/

        ];

        this.state = {

            disabled: true,
            focus: false,
            step: 0

        };

        this.values = {};

    }

    componentDidMount() {

        Globals.header( true );

        this.fields.forEach( ( [ id ] ) => {

            this.values[ id ] = "";

        } );

        if ( this.refs.name ) {

            this.refs.name.focus();

        }

    }

    componentWillUnmount() {

        Globals.header( false );

    }

    onAgree = ( id ) => {

        this.values[ id ] = true;

        const player = Object.assign( {}, this.values );

        player.token = RandomToken();

        Globals.var( "player", player );

    }

    onBlur = () => {

        this.setState( {

            focus: false

        } );

    }

    onButton = ( submit ) => {

        if ( submit && !this.state.disabled ) {

            const player = Object.assign( {}, this.values );

            player.token = RandomToken();

            Globals.var( "player", player );

        }

        else if ( !submit ) {

            Globals.load( "/" );

        }

    }

    onDisagree = ( id ) => {

        if ( Globals.Settings.Mode ) {

            Globals.load( "/" );

        }

        else {

            this.setState( { step: 0 } );

        }

    }

    onFocus = () => {

        this.setState( {

            focus: true

        } );

    }

    onInput = ( e, value, id ) => {

        this.values[ id ] = value;

        this.validate();

    }

    onNext = ( value, id ) => {

        this.values[ id ] = value;

        const step = this.state.step + 1;

        if ( step < this.fields.length ) {

            this.setState( {

                step: step

            } );

        }

        else {

            const player = Object.assign( {}, this.values );

            player.token = RandomToken();

            Globals.var( "player", player );

        }

    }

    onPrevious = ( id ) => {

        const step = this.state.step - 1;

        if ( step >= 0 ) {

            this.setState( {

                step: step

            } );

        }

        else {

            Globals.load( "/" );

        }

    }

    submit = () => {

        this.onButton( true );

    }

    validate = () => {

        let disable = false;

        this.fields.forEach( ( [ id, placeholder, type, validate ] ) => {

            if ( !validate ) {

                return;

            }

            const value = typeof this.values[ id ] !== "undefined" ? this.values[ id ] : false;

            switch ( type ) {

                default:

                    if ( !value ) {

                        disable = true;

                    }

            }

        } );

        this.setState( {

            disabled: disable

        } );

    }

    renderBooth() {

        const step = this.state.step;
        const field = typeof this.fields[ step ] === "undefined" ? false : this.fields[ step ];

        if ( !field ) {

            return "";

        }

        let id, placeholder, type, mandatory;

        ( [ id, placeholder, type, mandatory ] = field );

        const fieldsContent = [

            (

                <Intro />

            ), (

                <div>

                    <h2>Vill du ha facit?</h2>

                    <hr />

                    <p>Om du väljer att fylla i din e-postadress så skickar vi dina resultat till dig så fort du spelat färdigt. Sida kommer inte att spara dina personuppgifter.</p>

                </div>

            )

        ];

        switch ( type ) {

            case "checkbox":

                return (

                    <ScreenTerms
                    
                        id={ id }
                        key={ id }
                        onAgree={ this.onAgree }
                        onDisagree={ this.onDisagree }
                    
                    />

                );

            default:

                return (

                    <ScreenField
                    
                        allowBack={ step || Globals.Settings.Mode }
                        id={ id }
                        key={ id }
                        mandatory={ mandatory }
                        onNext={ this.onNext }
                        onPrevious={ this.onPrevious }
                        placeholder={ placeholder }
                        type={ type }
                    
                    >

                        { fieldsContent[ step ] }

                    </ScreenField>

                );

        }

    }

    renderWeb() {

        this.buttons[0][4] = this.state.disabled;

        const fields = [];

        this.fields.forEach( ( [ id, placeholder, type ] ) => {

            switch( type ) {

                case "checkbox":

                    fields.push(

                        <Checkbox

                            id={ id }
                            key={ id }
                            onChange={ this.onInput }
                            text={ placeholder }

                        />

                    );

                    break;

                default:

                    fields.push(

                        <TextField
                        
                            id={ id }
                            key={ id }
                            onBlur={ this.onBlur }
                            onEnter={ this.submit }
                            onFocus={ this.onFocus }
                            onInput={ this.onInput }
                            placeholder={ placeholder }
                            ref={ id }
                            type={ type }
                        
                        />

                    );

            }

        } );

        return (

            <Center className="Register">

                <div className="RegisterContent">

                    { fields }

                    <p>Om du väljer att fylla i din e-postadress så skickar vi dina resultat till dig så fort du spelat färdigt. Sida kommer inte att spara dina personuppgifter.</p>

                </div>

                {

                    Globals.Settings.ShowButtons ? (

                        <Buttons
                        
                            block={ this.state.focus }
                            className="RegisterButtons"
                            items={ this.buttons }
                            onClick={ index => this.onButton( !index ) }
                        
                        />
                    
                    ) : ""

                }

            </Center>

        );

    }

    render() {

        if ( Globals.Settings.Mode ) {

            return this.renderWeb();

        }


        else {

            return this.renderBooth();

        }

    }

}

export default Register;