
import React from "react";
import "./share.scss";

import API from "Classes/API";
import Globals from "Classes/Globals";
import { Uri } from "Functions";

import Center from "Components/Layout/Center";
import Intro from "Components/Layout/Intro";
import Navigation from "Components/UI/Navigation";

class Share extends React.Component {

    constructor( props ) {

        super( props );

        this.items = [

            [ "Spela", "/spela" ],
            [ "Om FAKTA eller FIKTION", "/om" ]

        ];

        this.state = {

            error: false,
            result: false

        };

        this.mounted = false;
        
    }

    componentDidMount() {

        Globals.header( true );

        this.mounted = true;

        API.request( "game/result", {

            token: Uri(1)

        }, ( response ) => {

            if ( !this.mounted ) {

                return;

            }

            if ( response.error ) {

                this.setState( { error: true } );

            }

            else {

                this.setState( { result: response.data } );

            }

        } );

    }

    componentWillUnmount() {

        Globals.header( false );

        this.mounted = false;

    }

    render() {

        const content = [];

        if ( this.state.result ) {

            const points = parseInt( this.state.result.points, 10 );

            content.push(

                <div key="result" className="ShareResult">

                    <h2>

                        { this.state.result.name } har uppdaterat sin världsbild och fick { points } poäng. Gör det du också!

                    </h2>

                    <hr />

                    <Intro justText={ true } />

                </div>

            );

        }

        else if ( this.state.error ) {

            content.push(

                <div key="error" className="ShareError">

                    Resultatet hittades inte.

                </div>

            );

        }

        else {

            content.push(

                <div key="loading" className="ShareLoading">

                    

                </div>

            );

        }

        return (

            <Center className="Share">

                <div className="ShareContent">

                    { content }

                </div>

                <Navigation items={ this.items } { ...this.props } />

            </Center>

        );

    }

}

export default Share;