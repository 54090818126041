
import React from "react";
import "./logo.scss";

import Src from "./logo.png";

class Logo extends React.Component {

    render() {

        if ( this.props.text ) {

            const classNamesArray = [ "LogoText" ];

            if ( this.props.big ) {

                classNamesArray.push( "Big" );

            } 

            const classNamesString = classNamesArray.join( " " );

            return (

                <div className={ classNamesString }>Uppdatera Din Världsbild</div>

            );

        }

        else {

            const classNamesArray = [ "Logo" ];

            if ( this.props.big ) {

                classNamesArray.push( "Big" );

            } 

            const classNamesString = classNamesArray.join( " " );

            const style = {

                backgroundImage: "url(" + Src + ")"

            };

            return (

                <div className={ classNamesString } style={ style } />

            );

        }

    }

}

Logo.defaultProps = {

    big: false,
    text: true

};

export default Logo;