
import React from "react";

import Globals from "Classes/Globals";
import { NoOrphans } from "Functions";

import Buttons from "Components/UI/Buttons";
import Center from "Components/Layout/Center";

class Question extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            answered: false,
            right: false,
            wrong: false

        };

        this.buttons = [

            [ "Fakta", true, "green", Globals.Settings.BindTrue ],
            [ "Fiktion", false, "red", Globals.Settings.BindFalse ]

        ];

    }

    componentDidMount() {

        if ( !Globals.Settings.ShowButtons ) {

            window.addEventListener( "keyup", this.onKeyUp );

        }

    }

    componentWillUnmount() {

        if ( !Globals.Settings.ShowButtons ) {

            window.removeEventListener( "keyup", this.onKeyUp );

        }

    }

    onAnswer = ( answer ) => {

        if ( this.state.answered ) {

            return;

        }

        const isRight = answer === this.props.isTrue;

        this.setState( {

            answered: true,
            right: isRight,
            wrong: !isRight

        } );

        setTimeout( () => {

            this.props.onAnswer( isRight );

        }, 500 );

    }

    onKeyUp = ( e ) => {

        switch ( e.key ) {

            case Globals.Settings.BindFalse:

                this.onAnswer( false );

                break;

            case Globals.Settings.BindTrue:

                this.onAnswer( true );

                break;

            default:

                return;

        }

        e.preventDefault();
        e.stopPropagation();

    }

    render() {

        const classNamesArray = [ "GameQuestion" ];

        if ( this.state.answered ) {

            classNamesArray.push( "Answered" );

        }

        if ( this.state.right ) {

            classNamesArray.push( "IsRight" );

        }

        else if ( this.state.wrong ) {

            classNamesArray.push( "IsWrong" );

        }

        if ( this.props.pulse ) {

            classNamesArray.push( "Pulse" );

        }

        const classNamesString = classNamesArray.join( " " );

        return (

            <Center className={ classNamesString }>

                <div className="GameQuestionText">

                    <h2>{ "Påstående #" + this.props.index }</h2>

                    <hr />

                    <h3>{ NoOrphans( this.props.text ) }</h3>

                </div>

                {

                    Globals.Settings.ShowButtons ? (

                        <Buttons
                        
                            className="GameButtons"
                            items={ this.buttons }
                            onClick={ index => this.onAnswer( !index ) }
                        
                        />
                    
                    ) : ""

                }

            </Center>

        );

    }

}

Question.defaultProps = {

    index: 0,
    isTrue: false,
    onAnswer: () => {},
    pulse: false,
    text: "",

};


export default Question;
