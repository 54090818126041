
import React from "react";
import "./about.scss";

import Globals from "Classes/Globals";

import Center from "Components/Layout/Center";
import Navigation from "Components/UI/Navigation";

class About extends React.Component {

    constructor( props ) {

        super( props );

        this.items = [

            [ "Spela", "spela" ],
            [ "Tillbaka", "/" ]

        ];
        
    }

    componentDidMount() {

        Globals.header( true );

    }

    componentWillUnmount() {

        Globals.header( false );

    }

    render() {

        return (

            <Center className="About">

                <div className="AboutContent">

                    <h2>Hur snabbt kan du avslöja en lögn?</h2>

                    <hr />

                    <p>Mångas bild av tillståndet i världen är mörk. Vi ser bilder på krig och lidanden, vi läser om demokratier som hotas och en planet som blir överhettad.</p>

                    <p>Men väldigt mycket har också blivit bättre. Genom biståndsmyndigheten Sidas quiz <i>FAKTA eller FIKTION</i> kan du testa hur bra koll du har på läget i världen och samtidigt uppdatera din världsbild.</p>

                    <p>Vi på biståndsmyndigheten Sida arbetar på uppdrag av Sveriges riksdag och regering för att minska fattigdomen i världen och för att öka kunskapen om utvecklingen i världen samt hur svenskt bistånd bidrar.</p>

                </div>

                <Navigation items={ this.items } { ...this.props } />

            </Center>

        );

    }

}

export default About;