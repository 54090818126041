
import React from "react";

import Globals from "Classes/Globals";
import Sounds from "Classes/Sounds";
import { Url } from "Functions";

import Questions from "Import/JSON/questions.json";

import Buttons from "Components/UI/Buttons";
import Center from "Components/Layout/Center";
import Icon from "Components/UI/Icon";
import ShareButton from "Components/UI/ShareButton";
import Slider from "Components/UI/Slider";

class Done extends React.Component {

    constructor( props ) {

        super( props );

        this.backspace = Globals.Settings.Remap ? "-" : "Backspace";

        this.buttons1 = [

            [ <span><Icon feather="CornerDownLeft" /><span>Ny spelare</span></span>, true, "black", "Enter" ],
            [ <span><Icon feather="Repeat" /><span>Spela igen</span></span>, false, "black", "<" ]

        ];

        this.buttons2 = [

            [ "Starta om", true, "green" ],
            [ "Avsluta", false, "red" ]

        ];

    }

    componentDidMount() {

        const good = 10;

        if ( Globals.Settings.Mode && this.props.points >= good ) {

            Sounds.play( "success" );

        }

        else if ( Globals.Settings.Mode ) {

            Sounds.play( "gameover" );

        }

        Globals.header( true );

    }

    componentWillUnmount() {

        Globals.header( false );

    }

    navigate = ( index, restart ) => {

        if ( restart ) {

            this.props.onRestart();

        }

        else {

            Globals.load( "/" );

        }

    }

    render() {

        let navigation;
        let share = "";

        if ( Globals.Settings.Mode ) {

            navigation = (

                <Buttons
                
                    arrows={ false }
                    key="navigation"
                    items={ this.buttons2 }
                    onClick={ this.navigate }
                
                />

            );

            const url = Url( "resultat/" + this.props.player.token );
            const text = "Jag har uppdaterat min världsbild och fick " + this.props.points + " poäng. Gör det du också!";

            share = (

                <div className="GameDoneShare">

                    <ShareButton type="facebook" url={ url } text={ text } />
                    <ShareButton type="twitter" url={ url } text={ text } />

                </div>

            );

        }

        else {

            navigation = (

                <Buttons
                
                    arrows={ false }
                    key="buttons"
                    items={ this.buttons1 }
                    onClick={ this.props.onRestart }
                
                />

            );

        }

        const errors = [];

        this.props.errors.forEach( ( index ) => {

            const question = Questions[ index ];
            let source;

            if ( question[4] ) {

                source = (
                
                    <a href={ question[4] } target="_blank" rel="noopener noreferrer">{ question[3] }</a>
                    
                );

            }

            else {

                source = question[3];

            }

            errors.push(

                <div className="GameDoneError" key={ index }>

                    <p>{ question[0] }</p>

                    <p>{ question[1] ? (

                        <span>

                            Du svarade fiktion. Detta är <strong>FAKTA</strong>.

                        </span>

                    ) : (

                        <span>

                            Du svarade fakta. Detta är <strong>FIKTION</strong>. { question[2] }

                        </span>

                    ) }</p>

                    <div className="GameDoneSource">Källa: { source }</div>

                </div>

            );

        } );

        return (

            <Center className="GameDone">

                <h2>
            
                    Du fick { this.props.points } poäng!
                
                </h2>

                <hr />

                { errors.length ? (

                    <div className="GameDoneNoErrors">

                        <p>{ errors.length > 1 ? "På dessa frågor svarade du fel. Använd pilarna för att bläddra." : "Du svarade fel på denna fråga." }</p>

                        <Slider>

                            { errors }

                        </Slider>

                    </div>

                ) : (

                    <div className="GameDoneNoErrors">
                    
                        <p>Du hade inga fel.</p>
                    
                    </div>

                ) }

                { share }

                { navigation }

            </Center>

        );

    }

}

Done.defaultProps = {

    errors: [],
    onRestart: () => {},
    player: {},
    points: 0

};

export default Done;