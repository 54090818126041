
import React from "react";
import "./screenterms.scss";

import Globals from "Classes/Globals";

import Buttons from "Components/UI/Buttons";
import Center from "Components/Layout/Center";
import Icon from "Components/UI/Icon";

class ScreenTerms extends React.Component {

    constructor( props ) {

        super( props );

        this.backspace = Globals.Settings.Remap ? "-" : "Backspace";

        this.buttons = [

            [ <span><Icon feather="CornerDownLeft" /><span>Godkänn</span></span>, true, "black", "Enter" ],
            [ <span><Icon feather="ArrowLeft" /><span>Neka</span></span>, false, "black", this.backspace ]

        ];

        this.url = "https://sida.se/villkor"

    }

    onButton = ( agree ) => {

        if ( agree ) {

            this.props.onAgree( this.props.id );

        }

        else {

            this.props.onDisagree( this.props.id );

        }

    }

    render() {

        return (

            <Center className="ScreenTerms">

                <div className="ScreenTermsContent">

                    <h2>Godkänner du att dina personuppgifter sparas?</h2>

                    <hr />

                    <p>Fråga oss gärna om du vill veta mer om hur Sida behandlar personuppgifter.</p>

                </div>

                <Buttons

                    arrows={ false }        
                    className="ScreenTermsButtons"
                    items={ this.buttons }
                    onClick={ index => this.onButton( !index ) }
                
                />

            </Center>

        );

    }

}

ScreenTerms.defaultProps = {

    id: "",
    onAgree: () => {},
    onDisagree: () => {}

};

export default ScreenTerms;